
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
$fa-font-path: "../fonts" !default;

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~admin-lte/dist/css/adminlte.css';

@import '~font-awesome/scss/font-awesome.scss';
@import '~icheck/skins/square/blue.css';
@import '~toastr/toastr.scss';
@import '~daterangepicker/daterangepicker.css';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.content-wrapper{min-height: calc(100vh - 96px) !important;}
.main-footer{font-size: 80%; padding-top: 10px; padding-bottom: 10px;}

div[class^='input-group-']{
    min-width: 40px;
    .fa{display: flex; width:100%; justify-content: center;}
}

.content-header{
    h1,.breadcrumb-item{
        text-transform: capitalize;
    }
}
[v-cloak] > * { display:none }